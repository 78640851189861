body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.mapbox-container {
  height: calc(100vh);
}

.mapbox-block {
 height: calc(100vh);   
}
.mapboxgl-map {
  height: 100%;
  width: 100%;
}
.mapboxgl-canvas {
  height: 100%;
  width: 100%;
}

.search-container {
  z-index: 1;
  position: absolute;
  top: 50px;
  left: 50px;
  width: 200px;
  text-align: left;
}

.search-container div {
  width: 100%;
}

.search-container input {
  width: 400px
}

.search-container__item {
  margin-bottom: 7px;
}

.autocomplete-container {
  display: table-row;
}

.autocomplete-container > div {
  display: table-cell;
}

.autocomplete-container ul {
  list-style-type: none;
  background-color: white;
  margin-block-start: 0em;
  padding-inline-start: 0px;
  font-size: 12px;
  position: absolute;
}

.autocomplete-container li {
  cursor: pointer;
  white-space: nowrap;
  padding-left: 10px;
  padding-right: 10px;
}

.trip-results-container {
  position: absolute;
  top: 50px;
  right: 50px;
  z-index: 1;
  background-color: rgba(255,255,255,0.75);
}

.trip-results-container ul {
  list-style: none;
  padding-left: unset;
  margin: unset;
}

.trip-results-container .trip-results-container__item {
  font-size: 12px;
  padding-top:2px;
  padding-bottom:4px;
  text-align: left;
  padding-right: 5px;
}


.example-code-view.markdown-body {
  z-index: 2;
  color: white;
  background-color: rgba(0,0,0,0.75);
  overflow-y: scroll;
  
  margin-bottom:100px;
}

.example-code-close-button {
  position:absolute;
  right:25px;
}

.markdown-body p {
  text-align: left;
}
.markdown-body ul {
  text-align: left;
}
.markdown-body blockquote {
  text-align: left;
}
.markdown-body table {
  text-align: left;
  color: black;
}
.markdown-body pre {
  background-color: unset !important;
  padding: unset !important;
}

.example-code-widget-ui {
  overflow-y: scroll;
  max-height: calc(40vh);
}

.example-code-view.example-code-view__right {
  position: absolute;
  right: 0px;
  top: 0px;
  height: 100%;
  width: calc(100% - 625px);

  padding-left: 50px;
  margin-left: 50px;
  padding-top: 10px;
  padding-right: 25px;
}

.example-code-view.example-code-view__left {
  z-index: 3;
  position: absolute;
  left: 0px;
  top: 0px;
  height: 100%;
  width: calc(100% - 625px);

  padding-left: 25px;
  margin-left: 0px;
  margin-right: 50px;
  padding-top: 10px;
  padding-right: 50px;
}

.example-code-header {
  flex: 1 1 150px;
  background-color: rgba(255,255,255,0.95);
  border-radius: 12px;
  font-size: 20px;
  height: 25px;
  margin: unset;
  margin-bottom: 10px;
}

.example-code-open-code button {
  margin-top: 10px;
  width: 100%;
  vertical-align: middle;
  height: 25px;
}

/* .directions-suggested-transports-container */

.directions-suggested-transports-container {
  position: absolute;
  top: 420px;
  left: 50px;
  width: 450px;
  z-index: 1;
}

.directions-suggested-transports-container .example-code-header {
  width: 450px;
}

.directions-suggested-transports-container .example-code-widget-ui {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 10px;
}

.directions-suggested-transports-container .directions-suggested-transports-item {
  font-size: 12px;
  padding: 10px;

  flex: 1 1 150px;
  width: 200px;
  background-color: rgba(255,255,255,0.95);
  border-radius: 12px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);

  cursor: pointer;
}

.directions-suggested-transports-item span.left {
  text-align: left;
}

.directions-suggested-transports-item span.right {
  text-align: right;
}

/* .directions-suggested-routes-container */

.directions-suggested-routes-container {
  position: absolute;
  top: 50px;
  right: 50px;
  width: 450px;
  z-index: 1;
}

.directions-suggested-routes-container .directions-suggested-routes-item {
  font-size: 12px;
  padding: 10px;

  flex: 1 1 150px;
  background-color: rgba(255,255,255,0.95);
  border-radius: 12px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);

  cursor: pointer;
}

.directions-suggested-routes-item span.left {
  text-align: left;
}

.directions-suggested-routes-item span.right {
  text-align: right;
}

.directions-suggested-routes-container .example-code-header {
  width: 450px;
}

.directions-suggested-routes-container .example-code-widget-ui {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
}

/* .directions-transport-types-container */

.directions-transport-types-container {
  position: absolute;
  top: 120px;
  left: 50px;
  width: 450px;
  z-index: 1;
}

.directions-transport-types-container .directions-transport-types-item {
  font-size: 12px;
  padding: 10px;

  flex: 1 1 150px;
  background-color: rgba(255,255,255,0.95);
  border-radius: 12px;

  display: grid;
  grid-template-columns: repeat(1, 1fr);

  cursor: pointer;
}

.directions-transport-types-item span.left {
  text-align: left;
}

.directions-transport-types-item span.right {
  text-align: right;
}

.directions-transport-types-container .example-code-header {
  width: 450px;
}

.directions-transport-types-container .example-code-widget-ui {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

/* .directions-legs-container */

.directions-legs-container {
  position: absolute;
  bottom: 3px;
  left: calc(50% - 200px);
  width: 400px;
  z-index: 1;
}

.directions-legs-container .directions-legs-item {
  font-size: 12px;
  padding: 10px;

  flex: 1 1 150px;
  background-color: rgba(255,255,255,0.95);
  border-radius: 12px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);

  cursor: pointer;
}

.directions-legs-item span.left {
  text-align: left;
}

.directions-legs-item span.right {
  text-align: right;
}

.directions-legs-container .example-code-header {
  width: 400px;
}

.directions-legs-container .example-code-widget-ui {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
}

/* .directions-steps-container */

.directions-steps-container {
  position: absolute;
  top: 50px;
  left: calc(50% - 200px);
  width: 400px;
  z-index: 1;
}

.directions-steps-container .directions-steps-item {
  font-size: 12px;
  padding: 10px;

  flex: 1 1 150px;
  background-color: rgba(255,255,255,0.95);
  border-radius: 12px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);

  cursor: pointer;
}

.directions-steps-item span.left {
  text-align: left;
}

.directions-steps-item span.right {
  text-align: right;
}

.directions-steps-container .example-code-header {
  width: 400px;
}

.directions-steps-container .example-code-widget-ui .directions-steps {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
}

/**/
.directions-stops-container {
  grid-column: 1/3;
  text-align: left;
  margin-left: 20px;
}

/*app-mode-container*/

.app-mode-container {
  background: rgba(255,255,255,0.5);
  z-index: 2;
  position:absolute;
  height: 30px;
  padding-top: 10px;
  text-align: left;
}

.test-buttons-container {
  position: absolute;
  z-index: 1;
  background: rgba(255,255,255,0.5);
  width: 100%;
  padding-top: 10px;
  height: 30px;
}

/* .stations-container */

.stations-container {
  position: absolute;
  top: 490px;
  left: 50px;
  width: 450px;
  z-index: 1;
}

.stations-container .stations-item {
  font-size: 12px;
  padding: 10px;
  margin: 10px;

  flex: 1 1 150px;
  background-color: rgba(255,255,255,0.95);
  border-radius: 12px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);

  cursor: pointer;
}

.stations-item span.left {
  text-align: left;
}

.stations-item span.right {
  text-align: right;
}

.stations-container .example-code-header {
  width: 450px;
}

.stations-container .example-code-widget-ui {
  max-height: calc(100vh - 565px);
}

.stations-container .example-code-widget-ui .stations {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
}

/* .stations-query-limits-container */

.stations-query-limits-container {
  background-color: rgba(255,255,255,0.6);
  position: absolute;
  top: 80px;
  left: 50px;
  width: 450px;
  z-index: 1;
}

.stations-query-limits-other-container {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  grid-gap: 5px;
}

.stations-query-limits-radius-container-metric {
  display: grid;
  grid-template-columns: 100px max-content max-content;
  grid-gap: 5px;
}

.stations-query-limits-radius-container span {
  display: inline-block;
}

.stations-query-limits-radius-container-type {
  display: inline-block;
}

.stations-query-limits-radius-container-metric label {
  text-align: right;
}
.stations-query-limits-radius-container-metric label:after {
  content: ":";
}
.stations-query-limits-radius-container-metric label:after:after {
  content: ":";
}

.stations-query-limits-radius-container-imperial {
  display: grid;
  grid-template-columns: 100px max-content max-content;
  grid-gap: 5px;
}

.stations-query-limits-radius-container-imperial label {
  text-align: right;
}
.stations-query-limits-radius-container-imperial label:after {
  content: ":";
}
.stations-query-limits-radius-container-imperial label:after:after {
  content: ":";
}

.stations-query-limits-container .example-code-header {
  width: 450px;
}

.stations-query-limits-container .example-code-widget-ui {

}

/* .stations-filter-types-container */

.stations-filter-types-container {
  position: absolute;
  top: 270px;
  left: 50px;
  width: 450px;
  z-index: 1;
}

.stations-filter-types-container .stations-filter-types-item {
  font-size: 12px;
  padding: 10px;

  flex: 1 1 150px;
  background-color: rgba(255,255,255,0.95);
  border-radius: 12px;

  display: grid;
  grid-template-columns: repeat(1, 1fr);

  cursor: pointer;
}

.stations-filter-types-item span.left {
  text-align: left;
}

.stations-filter-types-item span.right {
  text-align: right;
}

.stations-filter-types-container .example-code-header {
  width: 450px;
}

.stations-filter-types-container .example-code-widget-ui {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

/* .stations-timetable-container */

.stations-timetable-container {
  position: absolute;
  top: 50px;
  right: 50px;
  width: 450px;
  z-index: 1;
}

.stations-timetable-container .stations-timetable-item {
  font-size: 12px;
  padding: 10px;
  margin: 10px;

  flex: 1 1 150px;
  background-color: rgba(255,255,255,0.95);
  border-radius: 12px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);

  cursor: pointer;
}

.stations-timetable-item span.left {
  text-align: left;
}

.stations-timetable-item span.right {
  text-align: right;
}

.stations-timetable-container .example-code-header {
  width: 450px;
}

.stations-timetable-container .example-code-widget-ui .stations-timetable {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
}

/* ........................................................................... */
/* .lines-container */

.lines-container {
  position: absolute;
  top: 410px;
  left: 50px;
  width: 450px;
  z-index: 1;
}

.lines-container .lines-item {
  font-size: 12px;
  padding: 10px;
  margin: 10px;

  flex: 1 1 150px;
  background-color: rgba(255,255,255,0.95);
  border-radius: 12px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);

  cursor: pointer;
}

.lines-item span.left {
  text-align: left;
}

.lines-item span.right {
  text-align: right;
}

.lines-container .example-code-header {
  width: 450px;
}

.lines-container .example-code-widget-ui {
  max-height: calc(100vh - 565px);
}

.lines-container .example-code-widget-ui .lines {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
}

/* .lines-query-limits-container */

.lines-query-limits-container {
  background-color: rgba(255,255,255,0.6);
  position: absolute;
  top: 80px;
  left: 50px;
  width: 450px;
  z-index: 1;
}

.lines-query-limits-other-container {
  display: grid;
  grid-template-columns: max-content max-content max-content;
  grid-gap: 5px;
}

.lines-query-limits-radius-container-metric {
  display: grid;
  grid-template-columns: 100px max-content max-content;
  grid-gap: 5px;
}

.lines-query-limits-radius-container span {
  display: inline-block;
}

.lines-query-limits-radius-container-type {
  display: inline-block;
}

.lines-query-limits-radius-container-metric label {
  text-align: right;
}
.lines-query-limits-radius-container-metric label:after {
  content: ":";
}
.lines-query-limits-radius-container-metric label:after:after {
  content: ":";
}

.lines-query-limits-radius-container-imperial {
  display: grid;
  grid-template-columns: 100px max-content max-content;
  grid-gap: 5px;
}

.lines-query-limits-radius-container-imperial label {
  text-align: right;
}
.lines-query-limits-radius-container-imperial label:after {
  content: ":";
}
.lines-query-limits-radius-container-imperial label:after:after {
  content: ":";
}

.lines-query-limits-container .example-code-header {
  width: 450px;
}

.lines-query-limits-container .example-code-widget-ui {

}

/* .lines-filter-types-container */

.lines-filter-types-container {
  position: absolute;
  top: 270px;
  left: 50px;
  width: 450px;
  z-index: 1;
}

.lines-filter-types-container .lines-filter-types-item {
  font-size: 12px;
  padding: 10px;

  flex: 1 1 150px;
  background-color: rgba(255,255,255,0.95);
  border-radius: 12px;

  display: grid;
  grid-template-columns: repeat(1, 1fr);

  cursor: pointer;
}

.lines-filter-types-item span.left {
  text-align: left;
}

.lines-filter-types-item span.right {
  text-align: right;
}

.lines-filter-types-container .example-code-header {
  width: 450px;
}

.lines-filter-types-container .example-code-widget-ui {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-gap: 10px;
}

/* .line-container */
.line-container {
  position: absolute;
  top: 50px;
  right: 50px;
  width: 450px;
  z-index: 1;
}

.line-container .line-item {
  font-size: 12px;
  padding: 10px;
  margin: 10px;

  flex: 1 1 150px;
  background-color: rgba(255,255,255,0.95);
  border-radius: 12px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);

  cursor: pointer;
}

.line-container .station-item {
  font-size: 12px;
  padding: 10px;
  margin: 10px;

  flex: 1 1 150px;
  background-color: rgba(255,255,255,0.95);
  border-radius: 12px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);

  cursor: pointer;
}

.line-item span.left {
  text-align: left;
}

.line-item span.right {
  text-align: right;
}

.station-item span.left {
  text-align: left;
}

.station-item span.right {
  text-align: right;
}

.line-container .example-code-header {
  width: 450px;
}

.line-container .example-code-widget-ui {
  max-height: calc(100vh - 650px);
}

.line-container .example-code-widget-ui .lines {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
}

/* .lines-timetable-container */

.lines-timetable-container {
  position: absolute;
  top: 460px;
  right: 50px;
  width: 450px;
  z-index: 1;
}

.lines-timetable-container .lines-timetable-item {
  font-size: 12px;
  padding: 10px;
  margin: 10px;

  flex: 1 1 150px;
  background-color: rgba(255,255,255,0.95);
  border-radius: 12px;

  display: grid;
  grid-template-columns: repeat(2, 1fr);

  cursor: pointer;
}

.lines-timetable-item span.left {
  text-align: left;
}

.lines-timetable-item span.right {
  text-align: right;
}

.lines-timetable-container .example-code-header {
  width: 450px;
}

.lines-timetable-container .example-code-widget-ui {
  max-height: calc(100vh - 650px);
}

.lines-timetable-container .example-code-widget-ui .lines-timetable {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
  grid-gap: 10px;
}